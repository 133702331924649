import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/Events/InternationalYogaDay2023/1.jpg';
import p2 from 'assests/Photos/Events/InternationalYogaDay2023/2.jpg';
import p3 from 'assests/Photos/Events/InternationalYogaDay2023/3.jpg';
import p4 from 'assests/Photos/Events/InternationalYogaDay2023/4.jpg';


// import SidebarArticles from 'views/Dasara/components/SidebarArticles/SidebarArticles';
import { Typography } from '@mui/material';
import Container from 'components/Container';
import Events2023 from 'views/Events2023/Events2023';



const InternationalYogaDay2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    
    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      INTERNATIONAL DAY OF YOGA : 2023-24
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      {/* Classes: PRE-PRIMARY	 Date: 23 FEBRUARY 2023 */}
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        National Public School Yeshwantpur enthusiastically celebrated the International Day of Yoga on Wednesday, 
        21 June 2023, aiming to imbue students with a profound understanding of yoga and its myriad benefits. 
        The theme chosen for this year was "Yoga for Vasudhaiva Kutumbakam," which emphasizes our collective desire for a 
        unified global community. The dedicated students of Class 10 wholeheartedly engaged in the event, skilfully demonstrating 
        various Asanas that embody our shared vision of "One Earth, One Family, and One Future."
        {/* <br/><br/> */}
        {/* This spectacular annual event engulfed all the exciting and interesting components of celebration for students.
A ceremonial inaugural by the Principals gave a positive start to the festivities that followed. 
The event commenced with the lighting of the lamp by Principal Academics, Ms. Nikitaa Kalro, Principal Administration, Ms. Sheeja Manoj and the guest for the day, eminent resource person, Mrs. Lavanya Prasad. To invoke the blessings of the Almighty, a shloka was chanted by the teachers and students. Teachers and students assembled in the ground to witness the inaugural function. The event was declared open by the Principals and the revelries began. */}


        <br></br>
        In addition, the teachers actively participated in a special yoga session tailored for their well-being, known as 
        chair yoga. During this session, they comfortably sat on chairs and practiced rejuvenating Asanas that promote 
        relaxation of both the mind and body. By the end of the session, the teachers expressed a profound sense of 
        tranquillity and rejuvenation.
        <br></br>
        Overall, the commemoration of the International Day of Yoga at National Public School Yeshwantpur exemplified the 
        profound commitment to fostering physical and mental well-being among students and staff alike.


        </Typography>
      </Box>
      <Typography
          variant={'h5'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"Embracing Tranquillity and Inner Balance - International Day of Yoga: 2023"
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Events2023 />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default InternationalYogaDay2023;